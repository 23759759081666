import axios from "./axios";

import { API_URL } from "./constants";


const payrollApi = {
  async uploadPayroll(toUpload) {
    let csvfile = null;

    if (Array.isArray(toUpload.file) && toUpload.file.length > 0) {
      const lastFile = toUpload.file.pop();
      csvfile = lastFile.originFileObj;
    }

    const formData = new FormData();
    formData.append("csv", csvfile);

    try {
      const result = await axios.post(
        `${API_URL}/payroll/upload-csv`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default payrollApi;