import { all, put, call, takeLatest } from "redux-saga/effects";

import workerApi from "../../api/workerApi"
import * as types from "./workerActionTypes";
import checkAuth from "../auth/checkAuthSaga";

function *workers({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(workerApi.getWorkers, payload);
    yield put({ type: types.WORKERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.WORKER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *workersLite({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(workerApi.getWorkersLite, payload);
    yield put({ type: types.WORKERS_LITE_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.WORKER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *getWorker(id) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(workerApi.getWorker, id.payload);
    yield put({ type: types.WORKER_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.WORKER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *deleteWorker({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(workerApi.deleteWorker, id);
    yield put({ type: types.DELETE_WORKER_REQUEST_SUCCESS, payload: id });
  } catch (error) {
    yield checkAuth(error, put({ type: types.WORKER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *saveWorker({ payload: worker }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    let response;

    if (worker.id === -1) {
      if (worker.email) response = yield call(workerApi.createForeman, worker);
      else response = yield call(workerApi.createWorker, worker);
    }
    else {
      response = yield call(workerApi.editWorker, worker);
    }

    yield put({ type: types.SAVE_WORKER_REQUEST_SUCCESS, payload: response });
    if (typeof worker.callback === "function") worker.callback();
  } catch (error) {
    yield checkAuth(error, put({ type: types.WORKER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *switchWorkerFaceRecognitionFlag({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(workerApi.setWorkerFR, id);
    yield put({ type: types.SWITCH_FACE_REKOGNITION_FLAG_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.WORKER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *switchActiveWorkerFlag({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(workerApi.toggleActiveWorker, id);
    yield put({ type: types.TOGGLE_ACTIVE_WORKER_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.WORKER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.WORKERS_LITE_REQUEST, workersLite),
    yield takeLatest(types.WORKERS_REQUEST, workers),
    yield takeLatest(types.WORKER_REQUEST, getWorker),
    yield takeLatest(types.SAVE_WORKER_REQUEST, saveWorker),
    yield takeLatest(types.DELETE_WORKER_REQUEST, deleteWorker),
    yield takeLatest(types.SWITCH_FACE_REKOGNITION_FLAG_REQUEST, switchWorkerFaceRecognitionFlag),
    yield takeLatest(types.TOGGLE_ACTIVE_WORKER_REQUEST, switchActiveWorkerFlag)
  ])
}
