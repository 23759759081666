import React from "react";

const Header = () => {
  return (
    <div className="header">
      <img className="header--logo" src="/images/trident-icon.png"/>
      <p className="header--title">TRI Timesheets</p>
    </div>
  )
};

export default Header;
