import { all, put, call, takeLatest } from "redux-saga/effects";

import companyApi from "../../api/companyApi"
import * as types from "./companyActionsTypes";
import checkAuth from "../auth/checkAuthSaga";

function *companies() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(companyApi.getCompanies);
    yield put({ type: types.COMPANIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.COMPANY_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *deleteCompany({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(companyApi.deleteCompany, payload);
    yield put({ type: types.COMPANY_DELETE_REQUEST_SUCCESS, payload });
  } catch (error) {
    yield checkAuth(error, put({ type: types.COMPANY_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *saveCompany(company) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    let response;

    if (company.payload.id === -1) {
      response = yield call(companyApi.createCompany, company.payload);
    }
    else {
      response = yield call(companyApi.editCompany, company.payload);
    }

    yield put({ type: types.COMPANY_SAVE_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.COMPANY_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.COMPANIES_REQUEST, companies),
    yield takeLatest(types.COMPANY_SAVE_REQUEST, saveCompany),
    yield takeLatest(types.COMPANY_DELETE_REQUEST, deleteCompany),
  ])
}
