import * as types from "./companyActionsTypes";

export const initialState = {
  companies: [],
  editingId: null,
  processing: false,
  error: {},
}

const company = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.COMPANIES_REQUEST_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        error: {}
      };

    case types.COMPANY_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        companies: [...state.companies.filter(c => !(c.id === action.payload.id || c.id === -1)), action.payload],
        editingId: null
      }


    case types.COMPANY_ADD_NEW:
      return {
        ...state,
        editingId: -1,
        companies: [{ id: -1, name: action.payload, createdAt: "", updatedAt: "" }, ...state.companies]
      }

    case types.COMPANY_EDIT:
      return {
        ...state,
        editingId: action.payload
      }

    case types.COMPANY_DELETE_REQUEST_SUCCESS:

      return {
        ...state,
        companies: state.companies.filter(c => c.id !== action.payload),
        editingId: null
      }

    case types.COMPANY_CANCEL_EDIT:
      return {
        ...state,
        companies: state.companies.filter(c => c.id !== -1),
        editingId: null
      }

    case types.COMPANY_REQUEST_ERROR:
      return { ...state, error: action.error };

    default:
      return state;
  }
}

export default company;