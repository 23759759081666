import { all, put, call, takeLatest } from "redux-saga/effects";

import dashboardApi from "../../api/dashboardApi"
import * as types from "./dashboardActionsTypes";
import checkAuth from "../auth/checkAuthSaga";

function *dashboard({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING });

  try {
    const response = yield call(dashboardApi.getDashboard, payload);
    yield put({ type: types.GET_DASHBOARD_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.DASHBOARD_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_DASHBOARD, dashboard),
  ])
}
