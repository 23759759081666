export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_REQUEST_SUCCESS = "REGISTER_REQUEST_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";

export const RESET_PASSWORD_CHECK_REQUEST = "RESET_PASSWORD_CHECK_REQUEST";
export const RESET_PASSWORD_CHECK_REQUEST_SUCCESS = "RESET_PASSWORD_CHECK_REQUEST_SUCCESS";

export const AUTH_REQUEST_ERROR = "AUTH_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_AUTH_ERROR";

export const TOGGLE_PROCESSING = "TOGGLE_AUTH_PROCESSING";

