import { all, put, call, takeLatest } from "redux-saga/effects";

import reportApi from "../../api/reportApi"
import * as types from "./reportActionsTypes";
import checkAuth from "../auth/checkAuthSaga";

function *report({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(reportApi.getMoneyReport, payload);
    yield put({ type: types.MONEY_REPORT_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.MONEY_REPORT_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.MONEY_REPORT_REQUEST, report),
  ])
}
