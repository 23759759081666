export const TIME_SHEET_REQUEST = "TIME_SHEET_REQUEST";
export const TIME_SHEET_REQUEST_SUCCESS = "TIME_SHEET_REQUEST_SUCCESS";

export const SAVE_TIME_SHEET_REQUEST = "SAVE_TIME_SHEET_REQUEST";
export const SAVE_TIME_SHEET_REQUEST_SUCCESS = "SAVE_TIME_SHEET_REQUEST_SUCCESS";

export const APPROVE_TIME_SHEETS_REQUEST = "APPROVE_TIME_SHEETS_REQUEST";
export const APPROVE_TIME_SHEETS_REQUEST_SUCCESS = "APPROVE_TIME_SHEETS_REQUEST_SUCCESS";

export const REPORT_REQUEST = "REPORT_REQUEST";
export const REPORT_REQUEST_SUCCESS = "REPORT_REQUEST_SUCCESS";

export const SAVE_CORRECTED_REPORT_REQUEST = "SAVE_CORRECTED_REPORT_REQUEST";
export const SAVE_CORRECTED_REPORT_REQUEST_SUCCESS = "SAVE_CORRECTED_REPORT_REQUEST_SUCCESS";

export const NEW_CORRECTED_REPORT_REQUEST = "NEW_CORRECTED_REPORT_REQUEST";
export const NEW_CORRECTED_REPORT_REQUEST_SUCCESS = "NEW_CORRECTED_REPORT_REQUEST_SUCCESS";

export const TIME_SHEETS_REQUEST = "TIME_SHEETS_REQUEST";
export const TIME_SHEETS_REQUEST_SUCCESS = "TIME_SHEETS_REQUEST_SUCCESS";

export const TIME_SHEET_BY_WORKER_ID_REQUEST = "TIME_SHEET_BY_WORKER_ID_REQUEST";
export const TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS = "TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS";

export const CREATE_TIME_SHEET_BY_WORKER_ID_REQUEST = "CREATE_TIME_SHEET_BY_WORKER_ID_REQUEST";
export const CREATE_TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS = "CREATE_TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS";

export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_JOB_TITLE_ORDER = "SET_JOB_TITLE_ORDER";

export const TIME_SHEET_REQUEST_ERROR = "TIME_SHEET_REQUEST_ERROR";
export const REPORT_REQUEST_ERROR = "REPORT_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_TIME_SHEET_ERROR";
export const SET_PAGINATION = "SET_PAGINATION";

export const TOGGLE_PROCESSING = "TOGGLE_TIME_SHEET_PROCESSING";