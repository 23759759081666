import { all, put, call, takeLatest } from "redux-saga/effects";

import timesheetApi from "../../api/timesheetApi"
import * as types from "./timesheetActionsTypes";
import checkAuth from "../auth/checkAuthSaga";

function *timesheets({ payload: params }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(timesheetApi.getTimesheets, params);
    yield put({ type: types.TIME_SHEETS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *timesheet({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(timesheetApi.getTimesheet, id);
    yield put({ type: types.TIME_SHEET_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *timesheetByWorkerId({ payload: workerId }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(timesheetApi.getTimesheetByWorkerId, workerId);
    yield put({ type: types.TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *createTimesheetByWorkerId({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(timesheetApi.createTimesheetByWorkerId, payload);
    yield put({ type: types.CREATE_TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS, payload: response });
    if (typeof payload.callback === "function") payload.callback();
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *report({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(timesheetApi.getReport, payload);
    yield put({ type: types.REPORT_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.REPORT_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *saveReport({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(timesheetApi.saveReport, payload);
    yield put({ type: types.SAVE_CORRECTED_REPORT_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *newReportCreate({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    yield call(timesheetApi.newReportCreate, payload);
    yield put({ type: types.NEW_CORRECTED_REPORT_REQUEST_SUCCESS });
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *saveTimesheet({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(timesheetApi.saveTimesheet, payload.timesheet);
    yield put({ type: types.SAVE_TIME_SHEET_REQUEST_SUCCESS, payload: response });
    if (typeof payload.callback === "function") payload.callback();
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *approveTimesheet({ payload: params }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(timesheetApi.approveTimesheet, params);
    yield put({ type: types.APPROVE_TIME_SHEETS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.TIME_SHEET_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.TIME_SHEETS_REQUEST, timesheets),
    yield takeLatest(types.REPORT_REQUEST, report),
    yield takeLatest(types.TIME_SHEET_REQUEST, timesheet),
    yield takeLatest(types.SAVE_TIME_SHEET_REQUEST, saveTimesheet),
    yield takeLatest(types.APPROVE_TIME_SHEETS_REQUEST, approveTimesheet),
    yield takeLatest(types.SAVE_CORRECTED_REPORT_REQUEST, saveReport),
    yield takeLatest(types.NEW_CORRECTED_REPORT_REQUEST, newReportCreate),
    yield takeLatest(types.TIME_SHEET_BY_WORKER_ID_REQUEST, timesheetByWorkerId),
    yield takeLatest(types.CREATE_TIME_SHEET_BY_WORKER_ID_REQUEST, createTimesheetByWorkerId)
  ])
}
