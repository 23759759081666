export const COMPANY_ADD_NEW = "COMPANY_ADD_NEW"
export const COMPANY_EDIT = "COMPANY_EDIT"
export const COMPANY_SAVE_REQUEST = "COMPANY_SAVE_REQUEST"
export const COMPANY_SAVE_REQUEST_SUCCESS = "COMPANY_SAVE_REQUEST_SUCCESS"
export const COMPANY_DELETE_REQUEST = "COMPANY_DELETE_REQUEST"
export const COMPANY_DELETE_REQUEST_SUCCESS = "COMPANY_DELETE_REQUEST_SUCCESS"
export const COMPANY_CANCEL_EDIT = "COMPANY_CANCEL_EDIT"

export const COMPANIES_REQUEST = "COMPANIES_REQUEST";
export const COMPANIES_REQUEST_SUCCESS = "COMPANIES_REQUEST_SUCCESS";

export const COMPANY_REQUEST_ERROR = "COMPANY_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_COMPANY_ERROR";

export const TOGGLE_PROCESSING = "TOGGLE_COMPANY_PROCESSING";