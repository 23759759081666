import React from "react";
import { Route } from "react-router-dom";

import PrivateRoute from "./shared/routes/PrivateRoute";
import PublicRoute from "./shared/routes/PublicRoute";

// Layouts
import AuthLayout from "./shared/layouts/AuthLayout";

const NotFound = React.lazy(() => import("./shared/NotFound"));
const Home = React.lazy(() => import("./Dashboard/Dashboard"));
const Login = React.lazy(() => import("./Login/Login"));
const ResetPassword = React.lazy(() => import("./Login/ResetPassword"));
const ResetPasswordCheck = React.lazy(() => import("./Login/ResetPasswordCheck"));
const Logout = React.lazy(() => import("./Login/Logout"));
const Company = React.lazy(() => import("./Company/Company"));
const WorkerList = React.lazy(() => import("./Worker/WorkerList"));
const Worker = React.lazy(() => import("./Worker/Worker"));
const ObjectList = React.lazy(() => import("./ObjectBuilding/ObjectBuildingList"));
const ObjectBuilding = React.lazy(() => import("./ObjectBuilding/ObjectBuilding"));
const ObjectBuildingAddWorkers = React.lazy(() => import("./ObjectBuilding/ObjectBuildingAddWorkers"));
const JobTitle = React.lazy(() => import("./JobTitle/JobTitle"));
const Timesheet = React.lazy(() => import("./Timesheet/Timesheet"));
const TimesheetEdit = React.lazy(() => import("./Timesheet/TimesheetEdit"));
const TimesheetCreate = React.lazy(() => import("./Timesheet/TimesheetCreate"));
const Report = React.lazy(() => import("./Timesheet/Report"));
const MoneyReport = React.lazy(() => import("./Timesheet/MoneyReport"));
const TimesheetApprove = React.lazy(() => import("./Timesheet/TimesheetApprove"));
const UploadPayroll = React.lazy(() => import("./Payroll/UploadPayroll"));
const UserList = React.lazy(() => import("./User/UserList"));
const User = React.lazy(() => import("./User/User"));


const routes = [

  // Not Authorized users only - PublicRoute component
  // NOTE: you can pass layout prop. This layout component will wrapper your route component
  { path: "/login", component: Login, route: PublicRoute, layout: AuthLayout },
  { path: "/reset-password", component: ResetPassword, route: PublicRoute, layout: AuthLayout },
  { path: "/reset-password-check/:code", component: ResetPasswordCheck, route: PublicRoute, layout: AuthLayout },
  { path: "/logout", component: Logout, route: PrivateRoute, layout: AuthLayout },

  // Authorized users only - PrivateRoute component
  // NOTE: Can be restricted by passing roles array - roles: ["Admin"]
  { path: "/", exact: true, component: Home, route: PrivateRoute },
  { path: "/upload-payroll", component: UploadPayroll, route: PrivateRoute },
  { path: "/workers/:id(|[0-9]+|new|newForeman)", component: Worker, route: PrivateRoute },
  { path: "/workers", component: WorkerList, route: PrivateRoute },
  { path: "/users/:id(|[0-9]+|new|newForeman)", component: User, route: PrivateRoute },
  { path: "/users", component: UserList, route: PrivateRoute },
  { path: "/companies", component: Company, route: PrivateRoute },
  { path: "/objects/:id(|[0-9]+)/add-worker", component: ObjectBuildingAddWorkers, route: PrivateRoute },
  { path: "/objects/:id(|[0-9]+|new)", component: ObjectBuilding, route: PrivateRoute },
  { path: "/objects", component: ObjectList, route: PrivateRoute },
  { path: "/job-titles", component: JobTitle, route: PrivateRoute },
  { path: "/timesheets/new", component: TimesheetCreate, route: PrivateRoute },
  { path: "/timesheets/:id(|[0-9]+)", component: TimesheetEdit, route: PrivateRoute },
  { path: "/timesheets", component: Timesheet, route: PrivateRoute },
  { path: "/reports", component: Report, route: PrivateRoute },
  { path: "/money-reports", component: MoneyReport, route: PrivateRoute },
  { path: "/approval", component: TimesheetApprove, route: PrivateRoute },
  // 404 Not Found route
  { path: "*", component: NotFound, route: Route },
];

export default routes;
