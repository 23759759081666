import { all, put, call, takeLatest } from "redux-saga/effects";

import objectApi from "../../api/objectApi"
import * as types from "./objectBuildingActionsTypes";
import checkAuth from "../auth/checkAuthSaga";

function *getObjectBuildings() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(objectApi.getObjects);
    yield put({ type: types.OBJECTS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.OBJECT_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *getObjectBuilding({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(objectApi.getObject, id);
    yield put({ type: types.OBJECT_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.OBJECT_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *saveObjectBuilding({ payload: objectBuilding }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    let response;

    if (objectBuilding.id === -1) {
      response = yield call(objectApi.createObject, objectBuilding);
    }
    else {
      response = yield call(objectApi.editObject, objectBuilding);
    }

    yield put({ type: types.SAVE_OBJECT_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.OBJECT_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *objectBuildingDelete({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(objectApi.deleteObject, id);
    yield put({ type: types.DELETE_OBJECT_REQUEST_SUCCESS, payload: id });
  } catch (error) {
    yield checkAuth(error, put({ type: types.OBJECT_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *addressLookup({ payload: text }) {
  try {
    const response = yield call(objectApi.addressLookup, text);
    yield put({ type: types.ADDRESS_LOOKUP_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.OBJECT_REQUEST_ERROR, error }))
  }
}

function *boundWorkers({ payload }) {
  try {
    const response = yield call(objectApi.boundWorkers, payload.objectId, payload.ids);
    yield put({ type: types.ADDRESS_LOOKUP_REQUEST_SUCCESS, payload: response });
    if (typeof payload.callback === "function") payload.callback();
  } catch (error) {
    yield checkAuth(error, put({ type: types.OBJECT_REQUEST_ERROR, error }))
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.OBJECTS_REQUEST, getObjectBuildings),
    yield takeLatest(types.OBJECT_REQUEST, getObjectBuilding),
    yield takeLatest(types.DELETE_OBJECT_REQUEST, objectBuildingDelete),
    yield takeLatest(types.SAVE_OBJECT_REQUEST, saveObjectBuilding),
    yield takeLatest(types.ADDRESS_LOOKUP_REQUEST, addressLookup),
    yield takeLatest(types.BOUND_WORKERS_REQUEST, boundWorkers),
  ])
}
