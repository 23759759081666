import * as types from "./workerActionTypes";

export const initialState = {
  workers: [],
  workersLite: [],
  page: 1,
  pages: 1,
  perPage: 10,
  total: 0,
  worker: {},
  processing: false,
  error: "",
}

const worker = (state = initialState, action) => {
  switch (action.type) {
    case types.WORKER_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.WORKERS_REQUEST_SUCCESS:
      return {
        ...state,
        workers: action.payload.data,
        pages: action.payload.pages,
        page: action.payload.page,
        perPage: action.payload.perPage,
        total: action.payload.total,
        error: ""
      }

    case types.WORKERS_LITE_REQUEST_SUCCESS:
      return {
        ...state,
        workersLite: action.payload,
        error: ""
      }

    case types.WORKER_REQUEST_SUCCESS:
      return {
        ...state,
        worker: action.payload,
        error: ""
      }

    case types.SAVE_WORKER_REQUEST_SUCCESS:
      return {
        ...state,
        worker: {},
        error: action.payload.faceUploadRes || ""
      }

    case types.ADD_WORKER:
      return {
        ...state,
        worker: { id: -1 },
        error: ""
      }

    case types.ADD_FOREMAN:
      return {
        ...state,
        worker: { id: -1, email: "sample@email.com" },
        error: ""
      }

    case types.DELETE_WORKER_REQUEST_SUCCESS:
      return {
        ...state,
        workers: state.workers.filter(w => w.id !== action.payload),
        error: ""
      }

    case types.SWITCH_FACE_REKOGNITION_FLAG_REQUEST_SUCCESS:
      return {
        ...state,
        workers: state.workers.map(w => {
          if (w.id === action.payload.id) {
            w.allowFaceRekognition = action.payload.allowFaceRekognition
          }

          return w;
        }),
        error: ""
      }

    case types.TOGGLE_ACTIVE_WORKER_REQUEST_SUCCESS:
      return {
        ...state,
        workers: state.workers.map(w => {
          if (w.id === action.payload.id) {
            w.isActive = action.payload.isActive
          }

          return w;
        }),
        error: ""
      }

    case types.CLEAR_WORKER:
      return {
        ...state,
        worker: {},
        error: ""
      }

    case types.SET_PAGINATION:
      return {
        ...state,
        ...action.payload,
        error: ""
      }

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      }
    default:
      return state;
  }
}

export default worker;