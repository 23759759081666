import axios from "./axios";

import { API_URL } from "./constants";
import { message } from "antd";


axios.interceptors.response.use(response => response, function (error) {

  if (error.response?.status === 409) {
    message.error({
      content: error.response.data
    });
    throw error.response.data;
  }

  if (error.response?.status === 422) {
    for (const err of error.response.data.errors) {
      message.error({
        content: err.param + err.value.slice(1, 50)
      });
    }
  }

  return Promise.reject(error);
});


const workerApi = {
  async getWorkers(params) {
    try {
      const result = await axios.get(
        `${API_URL}/worker`, {
          params
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getWorkersLite(params) {
    try {
      const result = await axios.get(
        `${API_URL}/worker/lite`, {
          params
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getWorker(id) {
    try {
      const result = await axios.get(
        `${API_URL}/worker/${id}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteWorker(id) {
    try {
      const result = await axios.delete(
        `${API_URL}/worker/${id}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async toggleActiveWorker(id) {
    try {
      const result = await axios.post(
        `${API_URL}/worker/${id}/toggleActive`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async setWorkerFR(id) {
    try {
      const result = await axios.post(
        `${API_URL}/worker/${id}/allow-face-rekognition`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createWorker(worker) {
    let photofile = null;
    let faceFiles = [];

    if (Array.isArray(worker.photo) && worker.photo.length > 0) {
      const lastFile = worker.photo.pop();
      photofile = lastFile.originFileObj;
    }
    if (Array.isArray(worker.face)) {
      faceFiles = worker.face.map(f => f.originFileObj);
    }
    const formData = new FormData();
    formData.append("photo", photofile);
    formData.append("companyId", worker.companyId);
    formData.append("firstName", worker.firstName);
    formData.append("jobTitleId", worker.jobTitleId);
    formData.append("lastName", worker.lastName);
    formData.append("regularAmount", worker.regularAmount);
    formData.append("overtimeAmount", worker.overtimeAmount);
    for (const face of faceFiles) {
      formData.append("face", face);
    }
    try {
      const result = await axios.post(
        `${API_URL}/worker`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createForeman(worker) {
    let photofile = null;
    let faceFiles = [];

    if (Array.isArray(worker.photo) && worker.photo.length > 0) {
      const lastFile = worker.photo.pop();
      photofile = lastFile.originFileObj;
    }
    if (Array.isArray(worker.face)) {
      faceFiles = worker.face.map(f => f.originFileObj);
    }
    const formData = new FormData();
    formData.append("photo", photofile);
    formData.append("companyId", worker.companyId);
    formData.append("firstName", worker.firstName);
    formData.append("jobTitleId", worker.jobTitleId);
    formData.append("lastName", worker.lastName);
    formData.append("regularAmount", worker.regularAmount);
    formData.append("overtimeAmount", worker.overtimeAmount);
    formData.append("email", worker.email);
    for (const face of faceFiles) {
      formData.append("face", face);
    }
    try {
      const result = await axios.post(
        `${API_URL}/worker/foreman`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editWorker(worker) {
    let photofile = null;
    let faceFiles = null;
    let deleteAvatar = null;

    if (Array.isArray(worker.photo) && worker.photo.length > 0) {
      const lastFile = worker.photo.pop();
      photofile = lastFile.originFileObj;
    } else if (worker.photo.length === 0) {
      deleteAvatar = 1;
    }

    faceFiles = [[], []];
    if (Array.isArray(worker.face)) {
      faceFiles = worker.face.reduce((acc, f) => {
        if (f.url) {
          acc[0].push(f.id)
        } else {
          acc[1].push(f.originFileObj)
        }
        return acc;
      }, [[], []]);
    }

    const formData = new FormData();
    if (worker.email) {
      formData.append("email", worker.email);
    }
    formData.append("photo", photofile);
    formData.append("companyId", worker.companyId);
    formData.append("firstName", worker.firstName);
    formData.append("jobTitleId", worker.jobTitleId);
    formData.append("lastName", worker.lastName);
    formData.append("regularAmount", worker.regularAmount);
    formData.append("overtimeAmount", worker.overtimeAmount);
    formData.append("deleteAvatar", deleteAvatar);
    for (const face of faceFiles[1]) {
      formData.append("face", face);
    }
    for (const faceId of faceFiles[0]) {
      formData.append("keepFacesId", faceId);
    }
    try {
      const result = await axios.patch(
        `${API_URL}/worker/${worker.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default workerApi;