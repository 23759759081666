export const USER_REQUEST = "USER_REQUEST";
export const USER_REQUEST_SUCCESS = "USER_REQUEST_SUCCESS";

export const ROLES_REQUEST = "ROLES_REQUEST";
export const ROLES_REQUEST_SUCCESS = "ROLES_REQUEST_SUCCESS";

export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_REQUEST_SUCCESS = "USERS_LIST_REQUEST_SUCCESS";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_REQUEST_SUCCESS = "DELETE_USER_REQUEST_SUCCESS";

export const RESTORE_USER_REQUEST = "RESTORE_USER_REQUEST";
export const RESTORE_USER_REQUEST_SUCCESS = "RESTORE_USER_REQUEST_SUCCESS";

export const ADD_USER = "ADD_USER";
export const CLEAR_USER = "CLEAR_USER";

export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
export const SAVE_USER_REQUEST_SUCCESS = "SAVE_USER_REQUEST_SUCCESS";

export const USER_REQUEST_ERROR = "USER_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_WORKER_ERROR";
export const SET_PAGINATION_PAGE = "SET_PAGINATION_PAGE";

export const TOGGLE_PROCESSING = "TOGGLE_COMPANY_PROCESSING";