import axios from "./axios";

import { API_URL } from "./constants";

const jobTitleApi = {
  async getJobTitles() {

    try {
      const result = await axios.get(
        `${API_URL}/job-title`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createJobTitle(jobTitle) {
    try {
      const result = await axios.post(
        `${API_URL}/job-title`,
        { title: jobTitle.title }
      );

      return result.data;
    }
    catch (err) {
      if (err.response?.status === 409) throw new Error("jobTitle already exists");
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editJobTitle(jobTitle) {

    try {
      const result = await axios.put(
        `${API_URL}/job-title/${jobTitle.id}`,
        { title: jobTitle.title }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteJobTitle(id) {
    try {
      const result = await axios.delete(
        `${API_URL}/job-title/${id}`
      );

      return result.status;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default jobTitleApi;
