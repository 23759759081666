import axios from "./axios";

import { API_URL } from "./constants";

const dashboardApi = {
  async getDashboard(params) {
    try {
      const result = await axios.get(
        `${API_URL}/dashboard`,
        {
          params
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default dashboardApi;
