import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Layout,
  Menu,
  Modal,
} from "antd";
import {
  CheckOutlined,
  FieldTimeOutlined,
  GoldOutlined,
  HomeOutlined,
  IdcardOutlined,
  LogoutOutlined,
  PieChartOutlined,
  PrinterOutlined,
  TagOutlined,
  UnorderedListOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

const { Content, Sider } = Layout;

import Header from "../Header";


function GeneralLayout({ children, match }) {

  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const onCollapse = collaps => {
    setCollapsed(collaps);
  };

  const showConfirm = (key) => {
    Modal.confirm({
      title: "Do You want to Logout?",
      icon: <LogoutOutlined />,
      onOk() {
        history.push(key);
      },
      onCancel() {},
    });
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="site-layout-background" style={{ padding: 0 }} />
      <Layout className="site-layout">
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo" />
          <Menu
            theme="dark"
            defaultSelectedKeys={[location.pathname]}
            defaultOpenKeys={
              ["/reports", "/timesheets", "/approval", "/money-reports"].includes(location.pathname) ? ["sub1"] : []}
            mode="inline"
            onClick={ ({ key }) => {
              if (key === "/logout") {
                showConfirm("/logout")
              } else {
                history.push(key);
              }
            }}>
            <Menu.Item key="/" icon={<PieChartOutlined />}>
              Dashboard
            </Menu.Item>
            <Menu.Item key="/workers" icon={<IdcardOutlined />}>
              Workers
            </Menu.Item>
            <Menu.Item key="/users" icon={<UserSwitchOutlined />}>
              Users
            </Menu.Item>
            <Menu.Item key="/companies" icon={<GoldOutlined />}>
              Companies
            </Menu.Item>
            <Menu.Item key="/objects" icon={<HomeOutlined />}>
              Job Sites
            </Menu.Item>
            <Menu.Item key="/job-titles" icon={<TagOutlined />}>
              Job Titles
            </Menu.Item>
            <Menu.SubMenu key="sub1" icon={<FieldTimeOutlined />} title="Timesheets">
              <Menu.Item key="/timesheets" icon={<UnorderedListOutlined />}>
                List
              </Menu.Item>
              <Menu.Item key="/reports" icon={<PrinterOutlined />}>
                Hours Report
              </Menu.Item>
              <Menu.Item key="/money-reports" icon={<PrinterOutlined />}>
                Money Report
              </Menu.Item>
              <Menu.Item key="/approval" icon={<CheckOutlined />}>
                Approval
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="/logout" icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ margin: "0 16px" }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

GeneralLayout.propTypes = {
  children: PropTypes.any,
};

export default GeneralLayout;
