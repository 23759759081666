import axios from "./axios";

import { API_URL } from "./constants";

const reportApi = {
  async getMoneyReport(params) {
    try {
      const result = await axios.get(
        `${API_URL}/report/company/${params.companyId}/money`,
        {
          params
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default reportApi;
