import * as types from "./userActionTypes";

export const initialState = {
  usersList: [],
  rolesList: [],
  setPaginationPage: 1,
  user: {},
  processing: false,
  error: {},
}
let userRestored = {}
let userDeleted = {}

const worker = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_REQUEST_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.USERS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        error: {}
      }

    case types.ROLES_REQUEST_SUCCESS:
      return {
        ...state,
        rolesList: action.payload,
        error: {}
      }

    case types.USER_REQUEST_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: {}
      }

    case types.SAVE_USER_REQUEST_SUCCESS:
      return {
        ...state,
        user: {},
        error: {}
      }

    case types.ADD_USER:
      return {
        ...state,
        user: { id: -1 },
        error: {}
      }

    case types.DELETE_USER_REQUEST_SUCCESS:
      userDeleted = state.usersList.find(u => u.id === action.payload);
      userDeleted.deletedAt = new Date();

      return {
        ...state,
        usersList: [...state.usersList.filter(u => u.id !== action.payload), userDeleted],
        error: {}
      }

    case types.RESTORE_USER_REQUEST_SUCCESS:
      userRestored = state.usersList.find(u => u.id === action.payload);
      userRestored.deletedAt = null;

      return {
        ...state,
        usersList: [...state.usersList.filter(u => u.id !== action.payload), userRestored],
        error: {}
      }

    case types.CLEAR_USER:
      return {
        ...state,
        user: {},
        error: {}
      }

    case types.SET_PAGINATION_PAGE:
      return {
        ...state,
        paginationPage: action.payload,
        error: {}
      }

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: {}
      }
    default:
      return state;
  }
}

export default worker;