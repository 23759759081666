import axios from "axios";
import Cookies from "universal-cookie";
import { message } from "antd";

class UnauthorizedError extends Error {
  constructor(errMessage) {
    super(errMessage);
    this.code = 401;
  }
}

const cookies = new Cookies();

axios.interceptors.request.use(
  config => {
    const token = cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    Promise.reject(error)
  }
);

axios.interceptors.response.use(response => response, function (error) {
  if (error.message === "Network Error" && !error.response) {
    message.error({
      content: "Unexpected server error"
    });

    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    cookies.remove("token");
    // const realStore = store();
    // realStore.dispatch({ type: "LOGOUT_REQUEST" });

    return Promise.reject(new UnauthorizedError());
  }

  if (error.response?.status === 403) {
    message.error({
      content: "You're not allowed to perform that action"
    });
  }

  return Promise.reject(error.response.data);
});

export default axios;
