export const OBJECT_REQUEST = "OBJECT_REQUEST";
export const OBJECT_REQUEST_SUCCESS = "OBJECT_REQUEST_SUCCESS";

export const ADD_OBJECT = "ADD_OBJECT";
export const CLEAR_OBJECT = "CLEAR_OBJECT";
export const BOUND_WORKERS_REQUEST = "BOUND_WORKERS_REQUEST";

export const ADDRESS_LOOKUP_REQUEST = "ADDRESS_LOOKUP_REQUEST";
export const ADDRESS_LOOKUP_REQUEST_SUCCESS = "ADDRESS_LOOKUP_REQUEST_SUCCESS";

export const DELETE_OBJECT_REQUEST = "DELETE_OBJECT_REQUEST";
export const DELETE_OBJECT_REQUEST_SUCCESS = "DELETE_OBJECT_REQUEST_SUCCESS";

export const SAVE_OBJECT_REQUEST = "SAVE_OBJECT_REQUEST";
export const SAVE_OBJECT_REQUEST_SUCCESS = "SAVE_OBJECT_REQUEST_SUCCESS";

export const OBJECTS_REQUEST = "OBJECTS_REQUEST";
export const OBJECTS_REQUEST_SUCCESS = "OBJECTS_REQUEST_SUCCESS";

export const OBJECT_REQUEST_ERROR = "OBJECT_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_OBJECT_ERROR";

export const TOGGLE_PROCESSING = "TOGGLE_OBJECT_PROCESSING";