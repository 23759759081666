export const MONEY_REPORT_REQUEST = "MONEY_REPORT_REQUEST";
export const MONEY_REPORT_REQUEST_SUCCESS = "MONEY_REPORT_REQUEST_SUCCESS";

export const SET_REPORT_START_DATE = "SET_REPORT_START_DATE";
export const SET_REPORT_END_DATE = "SET_REPORT_END_DATE";
export const SET_JOB_TITLE_ORDER = "SET_JOB_TITLE_ORDER";
export const SET_EXCLUDED_WORKERS = "SET_EXCLUDED_WORKERS";

export const MONEY_REPORT_REQUEST_ERROR = "MONEY_REPORT_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_MONEY_REPORT_ERROR";

export const TOGGLE_PROCESSING = "TOGGLE_MONEY_REPORT_PROCESSING";