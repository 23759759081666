import * as types from "./timesheetActionsTypes";
import { actionChannel } from "redux-saga-test-plan/matchers";
import moment from "moment";

export const initialState = {
  timesheets: [],
  timesheet: {},
  report: [],
  summary: {},
  jtOrder: "ascend",
  totalTime: "",
  objectId: null,
  companyId: null,
  startDate: moment().startOf("week"),
  endDate: moment().endOf("week"),
  savedTime: false,
  processing: false,
  selectedWorkerTimesheets: [],
  error: {},
  page: 1,
  pages: 1,
  perPage: 20,
  total: 0,
}

const timesheet = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.TIME_SHEETS_REQUEST_SUCCESS:
      return {
        ...state,
        timesheets: action.payload.data,
        page: action.payload.page,
        pages: action.payload.pages,
        perPage: action.payload.perPage,
        total: action.payload.total,
        error: {}
      }

    case types.TIME_SHEET_REQUEST_SUCCESS:
      return {
        ...state,
        timesheet: action.payload,
        error: {}
      }

    case types.REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        report: action.payload.report,
        summary: action.payload.summary,
        savedTime: false,
        error: {}
      }

    case types.SAVE_CORRECTED_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        savedTime: true
      }

    case types.NEW_CORRECTED_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        savedTime: true
      }

    case types.SAVE_TIME_SHEET_REQUEST_SUCCESS:
      return {
        ...state,
        timesheet: {},
        error: {}
      }

    case types.APPROVE_TIME_SHEETS_REQUEST_SUCCESS:
      return {
        ...state,
        timesheet: state.timesheets.map(ts => {
          const approvedTS = action.payload.find(t => t.id === ts.id);
          if (approvedTS) ts.approvedAt = approvedTS.approvedAt;

          return ts;
        }),
        error: {}
      }

    case types.TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS:
      return {
        ...state,
        selectedWorkerTimesheets: action.payload
      }

    case types.CREATE_TIME_SHEET_BY_WORKER_ID_REQUEST_SUCCESS:
      return {
        ...state,
        selectedWorkerTimesheets: []
      }

    case types.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload
      }

    case types.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload
      }

    case types.SET_JOB_TITLE_ORDER:
      return {
        ...state,
        jtOrder: action.payload
      }

    case types.REPORT_REQUEST_ERROR:
      return {
        ...state,
        report: [],
        error: action.error
      }

    case types.TIME_SHEET_REQUEST_ERROR: {
      return {
        ...state,
        error: action.error
      }
    }

    case types.SET_PAGINATION:
      return {
        ...state,
        ...action.payload,
        error: {}
      }

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: {},
      }

    default:
      return state;
  }
}

export default timesheet;