export const JOB_TITLE_ADD_NEW = "JOB_TITLE_ADD_NEW"
export const JOB_TITLE_EDIT = "JOB_TITLE_EDIT"
export const JOB_TITLE_SAVE_REQUEST = "JOB_TITLE_SAVE_REQUEST"
export const JOB_TITLE_SAVE_REQUEST_SUCCESS = "JOB_TITLE_SAVE_REQUEST_SUCCESS"
export const JOB_TITLE_DELETE_REQUEST = "JOB_TITLE_DELETE_REQUEST"
export const JOB_TITLE_DELETE_REQUEST_SUCCESS = "JOB_TITLE_DELETE_REQUEST_SUCCESS"
export const JOB_TITLE_CANCEL_EDIT = "JOB_TITLE_CANCEL_EDIT"

export const JOB_TITLES_REQUEST = "JOB_TITLES_REQUEST";
export const JOB_TITLES_REQUEST_SUCCESS = "JOB_TITLES_REQUEST_SUCCESS";

export const SET_JOB_TITLE_IDS = "SET_JOB_TITLE_IDS";

export const JOB_TITLE_REQUEST_ERROR = "JOB_TITLE_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_JOB_TITLE_ERROR";

export const TOGGLE_PROCESSING = "TOGGLE_JOB_TITLE_PROCESSING";