/* istanbul ignore file */

import { all } from "redux-saga/effects";

import authSagas from "./auth/authSagas";
import companySagas from "./company/companySagas";
import workerSagas from "./worker/workerSagas";
import objectSagas from "./objectBuilding/objectBuildingSagas";
import jobTitleSagas from "./jobTitle/jobTitleSagas";
import timesheetSagas from "./timesheet/timesheetSagas";
import dashboardSagas from "./dashboard/dashboardSagas";
import reportSagas from "./report/reportSagas";
import payrollSagas from "./payroll/payrollSagas";
import userSagas from "./user/userSagas";

export default function *rootSaga() {
  yield all([
    authSagas(),
    companySagas(),
    workerSagas(),
    objectSagas(),
    jobTitleSagas(),
    timesheetSagas(),
    dashboardSagas(),
    reportSagas(),
    payrollSagas(),
    userSagas(),
  ]);
}
