import * as types from "./reportActionsTypes";
import { actionChannel } from "redux-saga-test-plan/matchers";
import moment from "moment";

export const initialState = {
  moneyReport: [],
  excludedWorkers: [],
  jtOrder: null,
  reportStartDate: moment().startOf("week"),
  reportEndDate: moment().endOf("week"),
  excludeWorkers: [], //??????
  savedTime: false,
  processing: false,
  error: {},
}

const report = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.MONEY_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        moneyReport: action.payload.report,
        moneySummary: action.payload.summary,
        error: {}
      }

    case types.SET_REPORT_START_DATE:
      return {
        ...state,
        reportStartDate: action.payload
      }

    case types.SET_REPORT_END_DATE:
      return {
        ...state,
        reportEndDate: action.payload
      }

    case types.SET_JOB_TITLE_ORDER:
      return {
        ...state,
        jtOrder: action.payload
      }
    case types.SET_EXCLUDED_WORKERS:
      return {
        ...state,
        excludedWorkers: action.payload
      }

    default:
      return state;
  }
}

export default report;