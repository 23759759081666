import * as types from "./objectBuildingActionsTypes";

export const initialState = {
  objects: [],
  objectBuilding: {},
  addresses: [],
  editingId: null,
  processing: false,
  saved: false,
  error: {},
}

const objectBuilding = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.OBJECTS_REQUEST_SUCCESS:
      return {
        ...state,
        objects: action.payload,
        error: {}
      }

    case types.OBJECT_REQUEST_SUCCESS:
      return {
        ...state,
        objectBuilding: action.payload,
        error: {}
      }

    case types.ADD_OBJECT:
      return {
        ...state,
        objectBuilding: { id: -1 },
        error: {}
      }

    case types.DELETE_OBJECT_REQUEST_SUCCESS:

      return {
        ...state,
        objects: state.objects.filter(o => o.id !== action.payload),
        error: {}
      }

    case types.SAVE_OBJECT_REQUEST_SUCCESS:
      return {
        ...state,
        objectBuilding: action.payload,
        saved: true,
        error: {}
      }

    case types.CLEAR_OBJECT:
      return initialState;

    case types.ADDRESS_LOOKUP_REQUEST_SUCCESS:
      return {
        ...state,
        addresses: action.payload || []
      }

    case types.OBJECT_REQUEST_ERROR:
      return {
        ...state,
        error: { message: action.error }
      }
    case types.CLEAR_ERROR: {
      return {
        ...state,
        error: {}
      }
    }
    default:
      return state;
  }
}

export default objectBuilding;