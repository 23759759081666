import { all, put, call, takeLatest } from "redux-saga/effects";

import payrollApi from "../../api/payrollApi"
import * as types from "./payrollActionsTypes";
import checkAuth from "../auth/checkAuthSaga";

function *objectBuildingDelete({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(payrollApi.uploadPayroll, payload);
    yield put({ type: types.UPLOAD_PAYROLL_REQUEST_SUCCESS, payload });
    if (typeof payload.callback === "function") payload.callback()
  } catch (error) {
    yield checkAuth(error, put({ type: types.UPLOAD_PAYROLL_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.UPLOAD_PAYROLL_REQUEST, objectBuildingDelete),
  ])
}
