import { all, put, call, takeLatest } from "redux-saga/effects";

import jobTitleApi from "../../api/jobTitleApi"
import * as types from "./jobTitleActionsTypes";
import checkAuth from "../auth/checkAuthSaga";

function *getJobTitles() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(jobTitleApi.getJobTitles);
    yield put({ type: types.JOB_TITLES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.JOB_TITLE_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}


function *deleteJobTitle({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(jobTitleApi.deleteJobTitle, id);
    yield put({ type: types.JOB_TITLE_DELETE_REQUEST_SUCCESS, payload: id });
  } catch (error) {
    yield checkAuth(error, put({ type: types.JOB_TITLE_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *saveJobTitle({ payload: jobTitle }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    let response;

    if (jobTitle.id === -1) {
      response = yield call(jobTitleApi.createJobTitle, jobTitle);
    }
    else {
      response = yield call(jobTitleApi.editJobTitle, jobTitle);
    }

    yield put({ type: types.JOB_TITLE_SAVE_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.JOB_TITLE_REQUEST_ERROR, error }))
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.JOB_TITLES_REQUEST, getJobTitles),
    yield takeLatest(types.JOB_TITLE_SAVE_REQUEST, saveJobTitle),
    yield takeLatest(types.JOB_TITLE_DELETE_REQUEST, deleteJobTitle),
  ])
}
