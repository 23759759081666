import axios from "./axios";

import { API_URL } from "./constants";

const companyApi = {
  async getCompanies() {

    try {
      const result = await axios.get(
        `${API_URL}/company`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createCompany(company) {
    try {
      const result = await axios.post(
        `${API_URL}/company`,
        { name: company.name }
      );

      return result.data;
    }
    catch (err) {
      if (err.response?.status === 409) throw new Error("company already exists");
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editCompany(company) {

    try {
      const result = await axios.put(
        `${API_URL}/company/${company.id}`,
        { name: company.name }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteCompany(id) {

    try {
      const result = await axios.delete(
        `${API_URL}/company/${id}`
      );

      return result.status;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default companyApi;
