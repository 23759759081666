import axios from "./axios";

import { API_URL } from "./constants";
import { message } from "antd";

axios.interceptors.response.use(response => response, function (error) {
  if (error.response?.status === 409) {
    message.error({
      content: error.response.data?.message
    });
    throw error.response.data?.message;
  }

  if (error.response?.status === 422) {
    for (const err of error.response.data.errors) {
      message.error({
        content: err.param + err.value.slice(1, 50)
      });
    }
  }

  return Promise.reject(error);
});

const userApi = {
  async getUsersList() {
    try {
      const result = await axios.get(
        `${API_URL}/users`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getRoles() {
    try {
      const result = await axios.get(
        `${API_URL}/users/roles`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getUser(id) {
    try {
      const result = await axios.get(
        `${API_URL}/users/${id}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteUser(id) {
    try {
      const result = await axios.delete(
        `${API_URL}/users/${id}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async restoreUser(id) {
    try {
      const result = await axios.post(
        `${API_URL}/users/${id}/restore`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createUser(user) {
    try {
      const result = await axios.post(
        `${API_URL}/users`,
        {
          ...user,
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editUser(user) {
    try {
      const result = await axios.patch(
        `${API_URL}/users/${user.id}`,
        {
          ...user
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default userApi;