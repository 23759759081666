import axios from "./axios";

import { API_URL } from "./constants";

const timesheetApi = {
  async getTimesheets(params) {
    try {
      const result = await axios.get(
        `${API_URL}/timesheet`,
        {
          params
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getTimesheet(id) {
    try {
      const result = await axios.get(
        `${API_URL}/timesheet/${id}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getTimesheetByWorkerId(workerId) {
    try {
      const result = await axios.get(
        `${API_URL}/timesheet/byWorker/${workerId}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createTimesheetByWorkerId({ startTime, endTime, workerId, objectId, date }) {
    try {

      const result = await axios.post(
        `${API_URL}/timesheet/byWorker`,
        {
          startTime, endTime, workerId, objectId, date
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async saveReport({ reportId, newDuration }) {
    try {
      const result = await axios.post(
        `${API_URL}/timesheet/report/${reportId}`,
        {
          duration: newDuration
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async newReportCreate(data) {
    try {
      const result = await axios.post(
        `${API_URL}/timesheet/report/new`,
        {
          ...data
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async saveTimesheet(timesheet) {
    try {
      const result = await axios.put(
        `${API_URL}/timesheet/${timesheet.id}`,
        {
          ...timesheet
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async approveTimesheet(params) {
    try {
      const result = await axios.post(
        `${API_URL}/timesheet/approve`,
        {
          ...params
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getReport(params) {

    try {
      const result = await axios.get(
        `${API_URL}/timesheet/report`,
        {
          params
        }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default timesheetApi;
