import { all, put, call, takeLatest } from "redux-saga/effects";

import userApi from "../../api/userApi"
import * as types from "./userActionTypes";
import checkAuth from "../auth/checkAuthSaga";

function *usersList() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(userApi.getUsersList);
    yield put({ type: types.USERS_LIST_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.USER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *getUser(id) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(userApi.getUser, id.payload);
    yield put({ type: types.USER_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.USER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *deleteUser({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(userApi.deleteUser, id);
    yield put({ type: types.DELETE_USER_REQUEST_SUCCESS, payload: id });
  } catch (error) {
    yield checkAuth(error, put({ type: types.USER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}


function *restoreUser({ payload: id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(userApi.restoreUser, id);
    yield put({ type: types.RESTORE_USER_REQUEST_SUCCESS, payload: id });
  } catch (error) {
    yield checkAuth(error, put({ type: types.USER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *getUserRoles() {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    const response = yield call(userApi.getRoles);
    yield put({ type: types.ROLES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkAuth(error, put({ type: types.USER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *saveUser({ payload: user }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    let response = null;

    if (user.id === -1) {
      yield call(userApi.createUser, user)
    }
    else {
      yield call(userApi.editUser, user);
    }

    yield put({ type: types.SAVE_USER_REQUEST_SUCCESS, payload: response });
    if (typeof user.callback === "function") user.callback();
  } catch (error) {
    yield checkAuth(error, put({ type: types.USER_REQUEST_ERROR, error }));
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.USERS_LIST_REQUEST, usersList),
    yield takeLatest(types.USER_REQUEST, getUser),
    yield takeLatest(types.SAVE_USER_REQUEST, saveUser),
    yield takeLatest(types.DELETE_USER_REQUEST, deleteUser),
    yield takeLatest(types.ROLES_REQUEST, getUserRoles),
    yield takeLatest(types.RESTORE_USER_REQUEST, restoreUser),
  ])
}
