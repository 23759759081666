import axios from "./axios";

import { API_URL } from "./constants";

const objectApi = {
  async getObjects() {

    try {
      const result = await axios.get(
        `${API_URL}/object`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async getObject(id) {

    try {
      const result = await axios.get(
        `${API_URL}/object/${id}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async deleteObject(id) {

    try {
      const result = await axios.delete(
        `${API_URL}/object/${id}`,
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async createObject(objectBuilding) {

    try {
      const result = await axios.post(
        `${API_URL}/object`,
        { ...objectBuilding }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async editObject(objectBuilding) {

    try {
      const result = await axios.patch(
        `${API_URL}/object/${objectBuilding.id}`,
        { ...objectBuilding }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async addressLookup(text) {

    try {
      const result = await axios.get(
        `${API_URL}/object/address/${text}`
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async boundWorkers(objectId, ids) {

    try {
      const result = await axios.post(
        `${API_URL}/object/${objectId}/bound-workers`,
        { workerIds: ids }
      );

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },
};

export default objectApi;
