import axios from "axios";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

import { API_URL } from "./constants";
const cookies = new Cookies();

const auth = {
  async login(data) {
    try {
      const result = await axios.post(`${API_URL}/admin-auth/login`, data);
      const token = result.data.token;
      const decodedToken = jwt_decode(token);
      // Save token in cookies

      cookies.set("token", token, { expires: new Date(decodedToken.exp * 1000) });
      // NOTE: If your token has expire date, than you should set it as cookie expire date
      // cookies.set("token", result.data, { expires: <some date> });

      return result.data;
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async register(data) {
    try {
      const result = await axios.post(`${API_URL}/register`, data);

      return result.data
    }
    catch (err) {
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async resetPassword(email) {
    try {
      const result = await axios.post(`${API_URL}/admin-auth/forgot-password`,
        { email }
      );

      return result.data
    }
    catch (err) {
      if (err.response?.status === 422) throw err.response.data.errors[0].msg;
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async resetPasswordCheck(data) {
    try {
      const result = await axios.post(`${API_URL}/admin-auth/check-forgot-password`,
        data
      );

      return result.data
    }
    catch (err) {
      if (err.response?.status === 422) throw err.response.data.errors[0].msg;
      if (err.response && err.response.data) throw err.response.data;
      throw err;
    }
  },

  async logout() {
    try {
      cookies.remove("token")
      return true;
    }
    catch (err) {
      throw { error: "logout error" }
    }
  },
};

export default auth;
