import * as types from "./jobTitleActionsTypes";

export const initialState = {
  jobTitles: [],
  jobTitleIds: [],
  editingId: null,
  processing: false,
  error: {},
}

const jobTitle = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.JOB_TITLES_REQUEST_SUCCESS:
      return {
        ...state,
        jobTitles: action.payload,
        error: {}
      }

    case types.JOB_TITLE_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        jobTitles: [...state.jobTitles.filter(c => !(c.id === action.payload.id || c.id === -1)), action.payload],
        editingId: null
      }

    case types.JOB_TITLE_ADD_NEW:
      return {
        ...state,
        editingId: -1,
        jobTitles: [{ id: -1, title: action.payload, createdAt: "", updatedAt: "" }, ...state.jobTitles]
      }

    case types.JOB_TITLE_EDIT:
      return {
        ...state,
        editingId: action.payload
      }

    case types.JOB_TITLE_DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        jobTitles: state.jobTitles.filter(c => c.id !== action.payload),
        editingId: null
      }

    case types.JOB_TITLE_CANCEL_EDIT:
      return {
        ...state,
        jobTitles: state.jobTitles.filter(c => c.id !== -1),
        editingId: null
      }

    case types.SET_JOB_TITLE_IDS:
      return {
        ...state,
        jobTitleIds: action.payload,
      }

    case types.JOB_TITLE_REQUEST_ERROR:
      return { ...state, error: { message: action.error } };

    case types.CLEAR_ERROR: {
      return {
        ...state,
        error: {}
      }

    }
    default:
      return state;
  }
}

export default jobTitle;