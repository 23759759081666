/* istanbul ignore file */

import { combineReducers } from "redux";

import auth from "./auth/authReducer";
import company from "./company/companyReducer";
import worker from "./worker/workerReducer";
import objectBuilding from "./objectBuilding/objectBuildingReducer";
import jobTitle from "./jobTitle/jobTitleReducer";
import timesheet from "./timesheet/timesheetReducer";
import dashboard from "./dashboard/dashboardReducer";
import report from "./report/reportReducer";
import payroll from "./payroll/payrollReducer";
import user from "./user/userReducer";
// Combine reducer into single one

const rootReducer = combineReducers({
  auth,
  company,
  worker,
  objectBuilding,
  jobTitle,
  timesheet,
  dashboard,
  report,
  payroll,
  user,
});

export default rootReducer;
