export const WORKER_REQUEST = "WORKER_REQUEST";
export const WORKER_REQUEST_SUCCESS = "WORKER_REQUEST_SUCCESS";

export const WORKERS_REQUEST = "WORKERS_REQUEST";
export const WORKERS_REQUEST_SUCCESS = "WORKERS_REQUEST_SUCCESS";

export const WORKERS_LITE_REQUEST = "WORKERS_LITE_REQUEST";
export const WORKERS_LITE_REQUEST_SUCCESS = "WORKERS_LITE_REQUEST_SUCCESS";

export const DELETE_WORKER_REQUEST = "DELETE_WORKER_REQUEST";
export const DELETE_WORKER_REQUEST_SUCCESS = "DELETE_WORKER_REQUEST_SUCCESS";

export const ADD_WORKER = "ADD_WORKER";
export const ADD_FOREMAN = "ADD_FOREMAN";

export const SAVE_WORKER_REQUEST = "EDIT_WORKER_REQUEST";
export const SAVE_WORKER_REQUEST_SUCCESS = "EDIT_WORKER_REQUEST_SUCCESS";

export const SWITCH_FACE_REKOGNITION_FLAG_REQUEST = "SWITCH_FACE_REKOGNITION_FLAG_REQUEST"
export const SWITCH_FACE_REKOGNITION_FLAG_REQUEST_SUCCESS = "SWITCH_FACE_REKOGNITION_FLAG_REQUEST_SUCCESS"

export const TOGGLE_ACTIVE_WORKER_REQUEST = "TOGGLE_ACTIVE_WORKER_REQUEST"
export const TOGGLE_ACTIVE_WORKER_REQUEST_SUCCESS = "TOGGLE_ACTIVE_WORKER_REQUEST_SUCCESS"

export const WORKER_REQUEST_ERROR = "WORKER_REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_WORKER_ERROR";
export const CLEAR_WORKER = "CLEAR_WORKER";
export const SET_PAGINATION = "SET_PAGINATION";

export const TOGGLE_PROCESSING = "TOGGLE_COMPANY_PROCESSING";