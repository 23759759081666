import * as types from "./dashboardActionsTypes";

export const initialState = {
  total: 0,
  closed: 0,
  totalHours: "",
  byObject: [],
  tenLastTimesheets: [],
  processing: false,
  error: {},
}

const company = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        closed: action.payload.closed,
        totalHours: action.payload.totalHours,
        tenLastTimesheets: action.payload.tenLastTimesheets,
        byObject: action.payload.byObject || [],
        error: {}
      };

    case types.CLEAR_DASHBOARD:
      return { ...initialState };

    case types.DASHBOARD_REQUEST_ERROR:
      return { ...state, error: action.error };

    default:
      return state;
  }
}

export default company;