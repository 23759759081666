import * as types from "./payrollActionsTypes";

export const initialState = {
  processing: false,
  saved: false,
  error: {},
}

const objectBuilding = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.UPLOAD_PAYROLL_REQUEST_SUCCESS:
      return {
        ...state,
        error: {}
      }

    case types.CLEAR_ERROR: {
      return {
        ...state,
        error: {}
      }
    }
    default:
      return state;
  }
}

export default objectBuilding;